var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BaseTableList',{attrs:{"dense":"","title":"Histórico"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}}],null,true)})]},proxy:true}])}),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"outlined":"","color":"primary","title":"Voltar"},on:{"click":_vm.back}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }