<template>
  <v-container>
    <BaseTableList dense title="Histórico">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDate } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';

export default {
  props: {
    customerId: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    options: {},
    headers: [
      { text: 'Data', value: 'createdAt' },
      { text: 'Beneficiário', value: 'beneficiaryName' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'Tipo de movimentação', value: 'movementType' },
      { text: 'Descrição', value: 'description' },
      { text: 'Usuário', value: 'userName' }
    ]
  }),

  methods: {
    formatDate,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;

        const movementService = new MovementService();
        const { status, data } =
          await movementService.getPagedEmployeeHistories({
            page,
            take: itemsPerPage,
            employeeId: this.employeeId
          });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
